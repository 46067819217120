.Prices {
  img {
    max-width: 30%;
  }
  /* responsive, form small screens, use 13px font size */
  @media (max-width: 479px) {
    img {
      max-width: 100%;
    }
  }
}