.Home {
  .quote {
    position: relative;
    text-align: center;
    color: white;
  }
  .quote-logo{
    max-width: 50%;
    opacity: 0.2;
  }

  /* Centered text */
  .centered {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  p {
    font-family: 'Merriweather', serif;
  }
  h5 {
    font-family: 'Blacker', serif;
  }

  .presentation-img {
    max-width: 30%;
  }
  @media (max-width: 479px) {
    .presentation-img {
      max-width: 75%;
    }
    .quote-logo {
      max-width: 75%;
    }
  }
}
