.sidebar > .MuiPaper-root {
    width: 240px;
    background: #343a40;
    color: white;

  img {
    width: 100%;
    padding: 25px 20px;
    margin: 0;
  }

  ul li {
    padding: 0;

    &:hover a {
     background: rgba(255, 255, 255, 0.6);
    }
  }

  ul li a {
    display: flex;
    align-items: center;

    padding: 8px 14px;

    width: 100%;
    height: 100%;

    color: white;
    text-decoration: none;

    &.active {
     background: rgba(255, 255, 255, 0.6);
    }
  }

// link icon
   .MuiListItemIcon-root {
       min-width: 0;
       margin-right: 15px;

     svg {
    fill: #ffffff;
     }
   }
}
